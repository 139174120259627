// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IdCardButtonPt_reset-list_2ul27{margin:0;padding:0;list-style:none;display:block}.IdCardButtonPt_uppercase-sm_2OakQ{font-size:12px;text-transform:uppercase;letter-spacing:.03667em}.IdCardButtonPt_icon_37QDy{display:block;width:27px;height:auto;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"reset-list": "IdCardButtonPt_reset-list_2ul27",
	"uppercase-sm": "IdCardButtonPt_uppercase-sm_2OakQ",
	"icon": "IdCardButtonPt_icon_37QDy"
};
module.exports = exports;
