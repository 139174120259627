import callingCodes from './callingCodes';

const removeCallingCode = function removeCallingCode(phoneNumber) {
  const codes = [];
  Object.keys(callingCodes).forEach(key => codes.push(callingCodes[key]));
  let cleanedNumber = phoneNumber;
  for (let i = 0; i < codes.length; i++) {
    cleanedNumber = cleanedNumber.replace(codes[i], '');
  }

  return cleanedNumber;
};

export default removeCallingCode;
