const idCardTranslationsKeys = {
  EE: {
    no_implementation: 'no-ee-id-card',
  },
  LV: {
    no_implementation: 'no-lv-id-card',
  },
  LT: {
    no_implementation: 'no-lt-id-card',
  },
  PT: {
    no_implementation: 'no-pt-id-card',
  },
  RS: {
    no_implementation: 'no-rs-id-card',
  },
  FI: {
    no_implementation: 'no-fi-id-card',
  },
  BE: {
    no_implementation: 'no-be-id-card',
  }
}

export default idCardTranslationsKeys;
