import countries from 'i18n-iso-countries';
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/et.json"));
countries.registerLocale(require("i18n-iso-countries/langs/lv.json"));
countries.registerLocale(require("i18n-iso-countries/langs/lt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fi.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));
countries.registerLocale(require("i18n-iso-countries/langs/cs.json"));
countries.registerLocale(require("i18n-iso-countries/langs/it.json"));

export default countries;
