import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import i18n from './i18n/i18n';
import vSelect from 'vue-select';
import createStore from './createStore';
import createClient from '@eid-easy/eideasy-browser-client';
import createMethodsConfig from './createMethodsConfig';

Vue.use(vueCustomElement);

Vue.config.productionTip = false;

Vue.customElement('eideasy-widget', App, {
  beforeCreateVueInstance: RootComponentDefinition => {
    const {propsData} = RootComponentDefinition;
    Vue.component('v-select', vSelect);
    RootComponentDefinition.i18n = i18n;

    const eidEasyMethodsConfig = createMethodsConfig({
      methodsConfigFilter: propsData.methodsConfigFilter,
    });

    const {store, actions, getters} = createStore({
      methodsConfig: eidEasyMethodsConfig,
    });
    RootComponentDefinition.store = store;
    RootComponentDefinition.storeActions = actions;
    RootComponentDefinition.storeGetters = getters;
    
    const eidEasyClient = createClient(propsData);
    const eidEasyOnSuccess = (result) => {
      if (propsData.onSuccess && typeof propsData.onSuccess === 'function') {
        return propsData.onSuccess(result);
      }
    };
    const eidEasyOnFail = (result) => {
      if (propsData.onFail && typeof propsData.onFail === 'function') {
        return propsData.onFail(result);
      }
    };
    const eidEasyBeforeMethodSelection = (result) => {
      if (propsData.beforeMethodSelection && typeof propsData.beforeMethodSelection === 'function') {
        return propsData.beforeMethodSelection(result);
      }
    };
    const eidEasyFormatFlashMessage = (message) => {
      if (propsData.formatFlashMessage && typeof propsData.formatFlashMessage === 'function') {
        return propsData.formatFlashMessage(message);
      } else {
        return message;
      }
    };

    RootComponentDefinition.eidEasyClient = eidEasyClient;
    RootComponentDefinition.eidEasyOnSuccess = eidEasyOnSuccess;
    RootComponentDefinition.eidEasyOnFail = eidEasyOnFail;
    RootComponentDefinition.eidEasyBeforeMethodSelection = eidEasyBeforeMethodSelection;
    RootComponentDefinition.eidEasyFormatFlashMessage = eidEasyFormatFlashMessage;
    RootComponentDefinition.eidEasyMethodsConfig = eidEasyMethodsConfig;

    return RootComponentDefinition;
  },
  attributeChangedCallback(name, oldValue, value) {
    console.info('attributeChangedCallback', name, oldValue, value);
  },
});


