import * as jose from 'jose';

const ALGORITHM = 'ES256';

export const decodeJWS = (jws: string) => jose.decodeJwt(jws);

export const verifyJWS = async (jws: string, publicKeyBase64: string) => {
  try {
    const spki = atob(publicKeyBase64);

    const ecPublicKey = await jose.importSPKI(spki, ALGORITHM);

    const { payload } = await jose.compactVerify(jws, ecPublicKey);
    const decodedPayload = new TextDecoder().decode(payload);

    return JSON.stringify(decodeJWS(jws)) === decodedPayload;
  } catch (err: any) {
    return false;
  }
};
